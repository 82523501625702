import React from 'react';
import styled from '@emotion/styled';
import { FormGrid, MoreActionsMenuButton } from "../../toolympus/components/primitives";
import { FormControlsForFields } from "../../toolympus/components/schemed";
import { IssueSlot, IssueSlotSchema } from "./useIssuesList";
import { Typography } from '@mui/material';
import { PowerEditorBase } from '../../toolympus/components/PowerDoc';
import { ApplicationRecord } from '../Applications/typings';
import { useChecklistSelectorSchema } from './Checklists/ChecklistSelectorContext';



export const TinyButton = styled.button<{ dehighlight?: boolean }>`
  border: none;
  outline: none;
  background: transparent;
  display: inline-block;
  padding: 4px 0px;
  color: ${props => props.dehighlight ? "inherit" : props.theme.palette.primary.main};
  font-size: 0.8rem;
  line-height: 1;
  font-family: ${props => props.theme.typography.fontFamily};
  cursor: pointer;
`;




export interface SlotsEditingPropsBase {
  updateSlot: (id: number, changes: Partial<IssueSlot>) => void;
  removeSlot: (id: number) => void;
  moveSlotOneStep: (id: number, direction: -1 | 1) => void;
  isCollapsed?: boolean;

  isDraggingApplication?: ApplicationRecord | null;
  dropApplication?: (slot: IssueSlot, r: ApplicationRecord) => void;
}


export interface SlotEditorProps extends SlotsEditingPropsBase {
  slot: IssueSlot;
}


export const ConfigSlotEditor = (props: SlotEditorProps) => {
  const { slot: s, updateSlot, removeSlot } = props;

  const checklistSchema = useChecklistSelectorSchema();

  return (
    <FormGrid className="slot-main" noMargin columns="1fr">
      <FormGrid className="title-type" noMargin columns="1fr 150px 40px">
        <FormControlsForFields
          fields={[
            ["title"],
            ["is_application_link", { controlProps: { size: "small" }}],
          ]}
          data={s}
          onChange={(o,c) => updateSlot(s.slot_id, c)}
          schema={IssueSlotSchema}
          />
        <MoreActionsMenuButton
          actions={[
            ["remove", "Удалить", () => removeSlot(s.slot_id)],
            ["moveup", "Выше", () => props.moveSlotOneStep(s.slot_id, -1)],
            ["movedow", "Ниже", () => props.moveSlotOneStep(s.slot_id, 1)],
          ]}
          strippedButton
          size="small"
          />
        
        
      </FormGrid>
      
      {!props.isCollapsed &&
        <FormGrid columns="1fr 150px 40px" noMargin>
          <FormGrid columns="1fr" gap="dense" noMargin>
            <Typography variant="caption" color="textSecondary">Описание</Typography>
            <PowerEditorBase
              content={s.description}
              update={v => updateSlot(s.slot_id, { description: v})}
              />
          </FormGrid>

          <FormControlsForFields
            fields={[
              ["checklist_id", { autoComplete: true }],
            ]}
            data={s}
            onChange={(o,c) => updateSlot(s.slot_id, c)}
            schema={checklistSchema.schema}
            />
        </FormGrid>}


    </FormGrid>
  )
}