import { useEffect, useState } from "react";
import { Issue, IssuesApiPath, IssueSlot } from "./useIssuesList";
import { apiFetch } from "../../toolympus/api/core";
import { useItemActionWithConfirmation } from "../../toolympus/api/useAction";

export const useEditIssueSlots = (issue: Issue) => {
  const [slots, setSlots] = useState<IssueSlot[]>([]);
  const [slotsChanges, setSlotsChanges] = useState<Record<number, Partial<IssueSlot>>>({});
  useEffect(() => {
    if(issue._id >= 0) {
      setSlots(issue.slots);
    }
  }, [issue]);


  useEffect(() => {
    const actualSlotsToUpdate = Object.keys(slotsChanges) as any as number[];
    const timeout = setTimeout(async () => {
      for (const sid of actualSlotsToUpdate) {
        const changes = slotsChanges[sid as any as number];
        await apiFetch<IssueSlot>(`${IssuesApiPath}/${issue._id}/slot/${sid}`, "put", changes)
          .then(sUpdates => {
            setSlots(slots => slots.map(sx => sx.slot_id === sid ? { ...sx, ...sUpdates } : sx));
            setSlotsChanges(sx => {
              const sxc = { ...sx };
              delete sxc[sid];
              return sxc;
            })
          });
      }
    }, 1000);

    return () => clearTimeout(timeout);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsChanges]);

  const removeSlot = useItemActionWithConfirmation<number, {}>(async (slotId) => {
    return await apiFetch<IssueSlot>(`${IssuesApiPath}/${issue._id}/slot/${slotId}`, "delete")
      .then(() => {
        setSlots(x => x.filter(s => s.slot_id !== slotId))
        return {};
      })
  }, {
    title: "Удалить слот",
    memoTrigger: issue._id,
  });

  const updateSlot = (id: number, changes: Partial<IssueSlot>) => {
    setSlots(sx => sx.map(s => s.slot_id === id ? { ...s, ...changes } : s));
    setSlotsChanges(x => ({ ...x, [id]: { ...(x[id] || {}), ...changes }}));
  };

  return {
    slots,
    addSlot: () => {
      const sdata: Partial<IssueSlot> = {
        title: "",
        issue_id: issue._id,
        slot_id: Math.max(0, ...slots.map(s => s.slot_id)) + 1,
        sort_order: Math.max(0, ...slots.map(s => s.sort_order)) + 10,
      }
      apiFetch<IssueSlot>(`${IssuesApiPath}/${issue._id}/slot`, "post", sdata)
        .then(s => setSlots(sx => {
          const withNew = [...sx, s];
          withNew.sort((a,b) => a.sort_order < b.sort_order ? -1 : 1);
          return withNew;
        }))
    },

    updateSlot,

    removeSlot: (id: number) => {
      removeSlot.run(id);
    },

    hasChanges: Object.keys(slotsChanges).length > 0,

    moveSlotOneStep: (id: number, direction: 1 | -1) => {
      const idx = slots.findIndex(s => s.slot_id === id);
      if(idx < 0) {
        return;
      }

      if(direction < 0) {
        // UP
        if(idx === 0) {
          const newOrder = Math.max(...slots.map(s => s.sort_order || 0)) + 10;
          updateSlot(id, { sort_order: newOrder });
          setSlots(sx => [...sx.slice(1), sx[0]]);
        } else {
          const newOrder = idx-1 === 0
            ? (slots[idx-1].sort_order || 0) - 10
            : (slots[idx-2].sort_order || 0) + 0.5 * ((slots[idx-1].sort_order || 0) - (slots[idx-2].sort_order || 0));
          updateSlot(id, { sort_order: newOrder });
          setSlots(sx => [...sx.slice(0,idx-1), sx[idx], sx[idx-1], ...sx.slice(idx+1)]);
        }
      } else {
        // DOWN
        if(idx === slots.length - 1) {
          const newOrder = Math.min(...slots.map(s => s.sort_order || 0)) - 10;
          updateSlot(id, { sort_order: newOrder });
          setSlots(sx => [sx[sx.length-1], ...sx.slice(0,sx.length-1)]);
        } else {
          const newOrder = idx+1 === slots.length - 1
            ? (slots[idx+1].sort_order || 0) + 10
            : (slots[idx+1].sort_order || 0) + 0.5 * ((slots[idx+2].sort_order || 0) - (slots[idx+1].sort_order || 0));
          updateSlot(id, { sort_order: newOrder });
          setSlots(sx => [...sx.slice(0,idx), sx[idx+1], sx[idx], ...sx.slice(idx+2)]);
        }
      }
    }
  }
}

export type EditIssueSlotsData = ReturnType<typeof useEditIssueSlots>;
