import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ChecklistItem } from './useChecklistConfigs';
import { useChecklistBatch } from './useChecklistsBatch';
import { Popover } from '@mui/material';
import { Checklist, ChecklistWrapper } from './Checklist';

const Bar = styled.div<{ progress?: number }>`
  flex: 1 0 auto;
  height: 3px;
  min-width: 40px;
  background: #D0D0D0;
  
  position: relative;

  &:after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.palette.success.light};
    transform-origin: 0 0;
    transform: scaleX(${props => props.progress});
  }
`;

const Item = styled.div<{ isFull?: boolean }>`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  
  & span {
    font-size: 0.85rem;
    color: ${props => props.isFull ? props.theme.palette.success.light : "inherit"};
  }
`;

interface Props {
  items: ChecklistItem[];
  renderEmpty?: boolean;
  onClick?: (e: MouseEvent) => void;
}

export const ChecklistProgressDisplay = (props: Props) => {
  if(!props.items.length && !props.renderEmpty) {
    return null;
  }

  const total = props.items.length;
  const progress = props.items.filter(x => x.is_checked).length;
  const progressPct = progress / (total || 1);

  return (
    <Item isFull={progress === total} onClick={props.onClick as any} className="checklist-progress">
      <Bar progress={progressPct} className="checklist-progress-bar" />
      <span className="checklist-progress-label">{progress} / {total}</span> 
    </Item>
  );
}


const PoppingChecklistWrapper = styled(ChecklistWrapper)`
  padding: 0.5rem;
  max-width: min(400px, 100vw);
`;


export const ChecklistProgressDisplayFromBatch = (props: Omit<Props, "items"> & { groupId: string, withPoppingChecklist?: boolean }) => {
  const { groupId, ...otherProps } = props;

  const [popperAnchorEl, setPopperAnchorEl] = useState<any>(null);

  const batch = useChecklistBatch();
  const items = batch.data.filter(i => !!props.groupId && i.group_id === props.groupId);

  return <>
    <ChecklistProgressDisplay
      items={items}
      {...otherProps}
      onClick={props.withPoppingChecklist
        ? e => { setPopperAnchorEl(e.target)}
        : props.onClick}
      />
    {popperAnchorEl &&
      <Popover
        anchorEl={popperAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={!!popperAnchorEl}
        onClose={() => setPopperAnchorEl(null)}>
        <Checklist
          items={items}
          update={batch.update}
          wrapper={PoppingChecklistWrapper}
          />
      </Popover>}
  </>
}