import { Button, ButtonProps, Menu, MenuItem } from '@mui/material';
import React, { ReactNode, useState } from 'react';


interface Props extends Pick<ButtonProps, "size" | "color"> {
  noAutoClose?: boolean;
  options: [string, ReactNode][];
  selected: string | null;
  update: (v: string) => void;
  keepMenu?: boolean;
}

export const DropdownMenuPicker = (props: Props) => {
  const [menuAnchor,setMenuAnchor] = useState<any>(null);

  const { selected, update, options, noAutoClose, keepMenu, ...otherProps } = props;

  const selectedItem = selected ? options.find(([o]) => o === selected) : null;
  const [,selectedLabel] = selectedItem || ["...", "..."];
  
  return (<>
    <Button size="small" color="primary" variant="outlined" onClick={e => setMenuAnchor(e.target)} {...otherProps}>
      {selectedLabel}
    </Button>
    
    {(!!menuAnchor || keepMenu) &&
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)} keepMounted={keepMenu}>
        {options
          .map(([key,label]) => (
            <MenuItem key={key} onClick={() => { update(key); if(!noAutoClose) { setMenuAnchor(null); }}}>{label}</MenuItem>
          ))}
      </Menu>}
  </>)
}
