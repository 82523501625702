import { useMemo } from "react";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useBrowserStoredValue } from "../../toolympus/hooks/useBrowserStoredValue";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useSmartList } from "../../toolympus/hooks/useSmartList";
import { useTableComments } from "../Administration/Comments";
import { ApplicationRecord } from "../Applications/typings";
import { ApplicationsApiPath, ApplicationsCommentsEntity, applicationSearchText } from "../Applications/useApplicationsList";
import { useEditIssueSlots } from "./useEditIssueSlots";
import { Issue, IssuesApiPath, IssueSchema } from "./useIssuesList"
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { toMap } from "../../toolympus/api/data";
import { useChecklistBatchData } from "./Checklists/useChecklistsBatch";


const useIssueApplications = () => {
  const { schema: applicationsSchema } = useSingleSchema(`${ApplicationsApiPath}/uiconfig`)
  const approvalStatusFilter = useBrowserStoredValue("all", "status", "__pl_issue_approval_status");
  const applications = useSmartList<ApplicationRecord>(ApplicationsApiPath, {
    newRecordDefault: {},
    schema: applicationsSchema,
    getRecordApiPath: item => `${ApplicationsApiPath}/${item._id}`,
    extraParams: {
      approval_status: approvalStatusFilter.value === "all" ? "" : approvalStatusFilter.value,
    },
    textFilterFn: applicationSearchText,
    sorting: {
      allowedFields: [
        "display_name",
        "email",
        "created_at",
        "editor_rating_avg",
        "editor_rating_1",
        "editor_rating_2",
        "editor_rating_3",
        "editor_rating_4",
        "editor_rating_5",
        "editor_rating_6",
      ]
    },
  });

  const comments = useTableComments(ApplicationsCommentsEntity);

  return {
    ...applications,
    approvalStatusFilter,
    comments,
    ensureLoaded: () => {},
  }
}

export type IssueApplicationsData = ReturnType<typeof useIssueApplications>;


export const useEditIssue = (id: number) => {
  const data = useCrudItem<Issue>(`${IssuesApiPath}/${id}`, {
    defaultValue: { _id: -1, title: "", slots: [] },
    noLoad: !id || id < 0,
  });

  const slots = useEditIssueSlots(data.data);

  const applications = useIssueApplications();

  const [applicationsFiltered, hasApplicationsInSlots] = useMemo(() => {
    const slotsApplicationsSelected = slots.slots.map(s => s.application_id).filter(x => !!x);
    if(!slotsApplicationsSelected.length) {
      return [applications.data, false];
    } else {
      return [applications.data.filter(r => !slotsApplicationsSelected.includes(+r._id)), true];
    }
  }, [applications.data, slots.slots]);

  const applicationsAll = useLoadedData<ApplicationRecord[]>(ApplicationsApiPath, [], !!hasApplicationsInSlots);
  const applicationsLabels = useMemo(() => {
    return toMap(applicationsAll.data, r => r._id, r => r.display_name);
  }, [applicationsAll.data]);


  const checklists = useChecklistBatchData("/api/checklist/item", data.data._id ? `issue:${data.data._id}` : "");

  return {
    ...data,
    slots,
    schema: IssueSchema,
    applications: {
      ...applications,
      data: applicationsFiltered,
    },
    applicationsLabels,
    checklists,
  }
  
}

