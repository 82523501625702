import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { IssueApplicationsData } from './useEditIssue';
import { EmptyState, Link } from '../../toolympus/components/primitives';
import { useEditorBoardConfiguration } from '../Administration/useEditorBoardConfiguration';
import { EditorRating } from '../Administration/EditorRating';
import { ApplicationRecord } from '../Applications/typings';

interface Props {
  data: IssueApplicationsData;
  startDragging: (a: ApplicationRecord | null) => void;
}


export const IssueApplicationsTable = (props: Props) => {
  const { sorting, data, limitTrigger, comments } = props.data;

  const editorBoard = useEditorBoardConfiguration();

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              sortDirection={sorting?.sort?.field === "display_name" ? sorting.sort.direction : undefined}
              >
              <TableSortLabel
                active={sorting?.sort?.field === "display_name"}
                direction={sorting?.sort?.direction}
                onClick={sorting ? () => sorting.setNextSort("display_name") : undefined}
                >
                Имя
              </TableSortLabel>
            </TableCell>

            <TableCell
              sortDirection={sorting?.sort?.field === "editor_rating_avg" ? sorting.sort.direction : undefined}
              >
              <TableSortLabel
                active={sorting?.sort?.field === "editor_rating_avg"}
                direction={sorting?.sort?.direction}
                onClick={sorting ? () => sorting.setNextSort("editor_rating_avg") : undefined}
                >
                Рейтинг
              </TableSortLabel>
            </TableCell>

            <TableCell>
            </TableCell>
              
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row,idx) => (
              <TableRow
                key={row._id}
                draggable
                onDragStart={() => props.startDragging(row)}
                onDragEnter={e => { e.preventDefault(); }}
                onDragOver={e => { e.preventDefault(); }}
                onDragLeave={e => { e.preventDefault(); }}
                onDrop={() => {
                  props.startDragging(null);
                }}>
                <TableCell>
                  <Link to={`/applications?id=${row._id}`} target="_blank" rel="noreferrer noopener">
                    {row.display_name}
                  </Link>
                </TableCell>

                <TableCell>
                  <EditorRating
                    maxRating={editorBoard.boardSettings.editor_rating_max || 10}
                    value={row.editor_rating_avg || 0}
                    digits={1}
                    />
                </TableCell>
                
                <TableCell>
                  {comments.buttonForRecord(`${row._id}`)}
                </TableCell>

              </TableRow>))}
        </TableBody>
    </Table>
    {limitTrigger.pagingAnchor}
    {(data.length === 0) && <EmptyState />}
</TableContainer>
  );
}
