import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { Issue, IssuesApiPath, IssueSchema } from "./useIssuesList"
import { useEditIssueSlots } from "./useEditIssueSlots";
import { useChecklistConfigs } from "./Checklists/useChecklistConfigs";



export const useEditIssueTemplate = () => {
  const data = useCrudItem<Issue>(`${IssuesApiPath}/template`, {
    defaultValue: { _id: -1, title: "", slots: [] },
  });

  const slots = useEditIssueSlots(data.data);

  const checklists = useChecklistConfigs("/api/checklist/config");


  return {
    ...data,
    slots,
    schema: IssueSchema,
    checklists,
  }
  
}
