import React from 'react';
import { useEditIssueTemplate } from './useEditIssueTemplate';
import { Form, FormGrid, LoadingIndicator, OccupyFreeSpace, SaveButton, useDialogState, useSaveable } from '../../toolympus/components/primitives';
import { SlotsListEditor } from './SlotsListEditor';
import { Button } from '@mui/material';
import { Add, Check, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useBrowserStoredValue } from '../../toolympus/hooks/useBrowserStoredValue';
import { ConfigSlotEditor } from './ConfigSlotEditor';
import { ChecklistsConfigurationDialog } from './Checklists/ChecklistsConfigurationDialog';
import { ChecklistSelectorContext, useChecklistSelectorSchemaData } from './Checklists/ChecklistSelectorContext';
import { FormControlsForFields } from '../../toolympus/components/schemed';

interface Props {

}

export const IssueConfigForm = (props: Props) => {
  const data = useEditIssueTemplate();

  useSaveable(data);

  const isCollapsed = useBrowserStoredValue("f", undefined, "__pl_issueconfig_collapsed");

  const checklistsDialog = useDialogState();

  const checklistSelector = useChecklistSelectorSchemaData(data.checklists.data);

  return (
    <Form
      title="Конфигурация номера"
      fitFullHeight
      headerItems={<>
        <OccupyFreeSpace />
        {data.slots.hasChanges && <>H</>}
        {data.isLoading && <LoadingIndicator sizeVariant="s" />}
        {data.hasChanges && <SaveButton action={() => data.save()} />}

        <Button
          size="small"
          color="primary"
          startIcon={<Check />}
          onClick={() => checklistsDialog.open()}>
            чеклисты
        </Button>
        
        <Button
          size="small"
          color="primary"
          startIcon={isCollapsed.value === "t" ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
          onClick={() => isCollapsed.update(isCollapsed.value === "t" ? "f" : "t")}>
            {isCollapsed.value === "t" ? "развернуть" : "свернуть"}
        </Button>
        <Button size="small" color="primary" startIcon={<Add />} onClick={() => data.slots.addSlot()}>добавить слот</Button>
      </>}>

      <FormGrid noMargin>
        <div />
        <div />
        <FormControlsForFields
          fields={[
            ["checklist_id"],
          ]}
          data={data.data}
          schema={checklistSelector.schema}
          onChange={(o,c) => data.update(c)}
          />
        
        {/* marginator */}
        <div />
      </FormGrid>
      
      <ChecklistSelectorContext.Provider value={checklistSelector}>
        <SlotsListEditor
          addSlot={data.slots.addSlot}
          slots={data.slots.slots}
          updateSlot={data.slots.updateSlot}
          removeSlot={data.slots.removeSlot}
          moveSlotOneStep={data.slots.moveSlotOneStep}
          isCollapsed={isCollapsed.value==="t"}
          slotComponent={ConfigSlotEditor}
          />
      </ChecklistSelectorContext.Provider>

      <ChecklistsConfigurationDialog
        data={data.checklists}
        {...checklistsDialog}
        />
    </Form>
  );
}
