import { useSmartList } from '../../toolympus/hooks/useSmartList';
import { useSingleSchema } from '../../toolympus/hooks/useSchema';
import { schemaWithConfigurableFields, useApplicationsConfiguration } from './useApplicationsConfiguration';
import { useMemo } from 'react';
import { ApplicationRecord } from './typings';
import { useTableComments } from '../Administration/Comments';
import { useBrowserStoredValue } from '../../toolympus/hooks/useBrowserStoredValue';
import { apiFetch } from '../../toolympus/api/core';

export const ApiPath = "/api/applications";
export const ApplicationsApiPath = `${ApiPath}/manage`

export const ApplicationsCommentsEntity = "application";

export const applicationSearchText = (r: ApplicationRecord) => `${r.display_name} ${r.email || ""}`

export const useApplicationsList = () => {
  const { schema: schemaBase } = useSingleSchema(`${ApplicationsApiPath}/uiconfig`)
  
  const approvalStatusFilter = useBrowserStoredValue("all", "status", "__pl_approval_status");
  const extraFilter = useBrowserStoredValue("all", "extra", "__pl_extra");

  const data = useSmartList<ApplicationRecord>(ApplicationsApiPath, {
    newRecordDefault: {},
    schema: schemaBase,
    getRecordApiPath: item => `${ApplicationsApiPath}/${item._id}`,
    extraParams: {
      approval_status: approvalStatusFilter.value === "all" ? "" : approvalStatusFilter.value,
    },
    textFilterFn: applicationSearchText,
    sorting: {
      allowedFields: [
        "display_name",
        "email",
        "created_at",
        "editor_rating_avg",
        "editor_rating_1",
        "editor_rating_2",
        "editor_rating_3",
        "editor_rating_4",
        "editor_rating_5",
        "editor_rating_6",
      ]
    },
    editRecordSettings: { dontResetOnSave: true },
  });

  const config = useApplicationsConfiguration();

  const schema = useMemo(
    () => schemaWithConfigurableFields(
      schemaBase,
      config.data.fields,
      { cropTitles: true }),
    [schemaBase, config.data]);

  const extraFilterField = useMemo(() => {
    return config.data.fields.find(f => (f as any).subtype === "extrafilter");
  }, [config.data]);

  const dataX = useMemo(() => {
    const d = data.data.map(s => {
      const copy = { ...s } as any;
      config.data.fields.forEach(f => {
        copy[f._id] = s.info[f._id];
      });
      return copy;
    });

    if(!extraFilterField || !extraFilter.value || extraFilter.value === "all") {
      return d;
    } else {
      return d.filter(r => r[extraFilterField._id] === extraFilter.value);
    }
  }, [data.data, config.data, extraFilterField, extraFilter.value]);


  const comments = useTableComments(ApplicationsCommentsEntity);

  const updateRating = (applicationId: string, ratingKey: string, ratingValue: number) => {
    return apiFetch<ApplicationRecord>(`${ApplicationsApiPath}/${applicationId}/rating`, "put", { [ratingKey]: ratingValue })
      .then(a => {
        if(data.editRecord.item && data.editRecord.item._id === a._id) {
          data.editRecord.setItem({ ...data.editRecord.item, [ratingKey]: (a as any)[ratingKey], editor_rating_avg: a.editor_rating_avg });
        }
        data.reload();
        return a;
      })
  }

  return {
    ...data,
    data: dataX,
    config,
    schema,
    review: data.editRecord,
    remove: data.remove,

    approvalStatusFilter,
    extraFilter,
    extraFilterField,
    updateRating,

    comments,
  }
}


