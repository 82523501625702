import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { AttachedFile, ChecklistItem } from './useChecklistConfigs';
import { Buttons, FormGrid } from '../../../toolympus/components/primitives';
import { AttachFileOutlined, CheckBoxOutlineBlank, CheckBoxOutlined, Close, CommentOutlined, KeyboardReturn } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { FileUploadDialog, useFileUploadDialog } from '../../../toolympus/components/files';
import { downloadFile } from '../../../toolympus/api/core';
import { useItemActionWithConfirmation } from '../../../toolympus/api/useAction';

export const ChecklistWrapper = styled(FormGrid)`
  width: 100%;
  min-width: 300px;
`;
ChecklistWrapper.defaultProps = { columns: "1fr", gap: "dense", noMargin: true };

const ItemMainLine = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 6px;
  padding-right: 20px;

  cursor: pointer;
  user-select: none;
`;

const Item = styled.div`
  width: 100%;
  min-height: 30px;
  position: relative;
  & .buttons {
    position: absolute;
    top: 3px;
    right: 3px;
    
    & > * {
      cursor: pointer;
      opacity: 0.65;
  
      &:hover {
        opacity: 1;
      }
    }
  }

  & .checklist-item-comment {
    padding-left: 31px;
    margin: 0rem 0 0.5em;
    white-space: pre-line;
    font-size: 0.85rem;
    line-height: 1;
    opacity: 0.82;
  }

  & .comment-editor {
    margin-left: 30px;
    width: calc(100% - 30px);
  }

  & .checklist-attachments {
    padding-left: 31px;
    display: flex;
    flex-flow: column;
    font-size: 0.85rem;
    
    opacity: 0.82;

    & > .attachment {
      padding-left: 16px;
      position: relative;
      cursor: pointer;

      & .icon {
        position: absolute;
        top: 2px;
        left: 0;
      }

      & .remove {
        visibility: hidden;
      }
      &:hover {
        & .remove {
          visibility: visible;
        }
      }
    }
  }
`;

interface Props {
  items: ChecklistItem[];
  update: (id: string, changes: Partial<ChecklistItem>) => void;
  wrapper?: React.ComponentType;
}

const ChecklistItemEditor = (props: { item: ChecklistItem } & Pick<Props, "update">) => {
  const { item, update } = props;
  const [isEditingComment,setIsEditingComment] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const attachment = useFileUploadDialog({
    apiPath: `/api/checklist/item/${item._id}/file`,
    onUpload: () => {
      // this forces an update (re-read) of the checklist item in the context
      // and is safe because we don't change item title in checklists
      setTimeout(() => update(item._id, { title: item.title }), 500);
    },
  });

  useEffect(() => {
    if(comment) {
      const timeout = setTimeout(() => update(item._id, { comment }), 1000);
      return () => clearTimeout(timeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, item._id]);

  const removeAttachment = useItemActionWithConfirmation(({ item, attachment }: { item: ChecklistItem, attachment: AttachedFile}) => {
    update(item._id, { files_attached: (item.files_attached || []).filter(a => a._id !== attachment._id )});
    return Promise.resolve();
  }, {
    title: "Удалить файл"
  });

  const startEditingComment = () => {
    setComment(item.comment || "");
    setIsEditingComment(true);
  }

  return (
    <Item key={item._id} className="checklist-item">
      <ItemMainLine
        role="checkbox"
        aria-checked={item.is_checked}
        onClick={() => update(item._id, { is_checked: !item.is_checked })}>
        
        {item.is_checked
          ? <CheckBoxOutlined color="primary" fontSize="medium" />
          : <CheckBoxOutlineBlank color="action" fontSize="medium" />}

        <span className="checklist-item-label">{item.title}</span>

      </ItemMainLine>
      <Buttons className="buttons">
        <AttachFileOutlined color="action" fontSize="small" onClick={() => attachment.dialog.open()} />
        <CommentOutlined color="action" fontSize="small" onClick={() => startEditingComment()} />
      </Buttons>

      {isEditingComment ?
        <TextField
          className="comment-editor"
          value={comment || ""}
          onChange={e => setComment(e.target.value)}
          autoFocus
          onBlur={() => setIsEditingComment(false)}
          multiline
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end"><KeyboardReturn /></InputAdornment>
          }}
          />
        : item.comment &&
          <p className="checklist-item-comment" onClick={() => startEditingComment()}>
            {item.comment}
          </p>}
      
      {!!item.files_attached?.length &&
        <div className="checklist-attachments">
          {item.files_attached.map(f => (
            <span className="attachment" key={f._id} onClick={() => downloadFile(`/api/files/${f._id}`, f.filename)}>
              <AttachFileOutlined className="icon" color="inherit" fontSize="inherit" />
              {f.filename}

              <Close className="remove" fontSize="inherit" color="inherit" onClick={e => { e.stopPropagation(); removeAttachment.run({ item, attachment: f }) }} />
            </span>
          ))}
        </div>}

      {attachment.dialog.isOpen &&
        <FileUploadDialog
          title="Xfile"
          data={attachment}
          />}
    </Item>
  )
}


export const Checklist = (props: Props) => {
  const { items, update } = props;
  const WrapperX = props.wrapper || ChecklistWrapper;

  return (
    <WrapperX className="checklist">
      {items.map(item => (
        <ChecklistItemEditor key={item._id} item={item} update={update} />
      ))}
    </WrapperX>
  );
}
