import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { ChecklistConfig } from './useChecklistConfigs';
import { createSelectSchema, Schema } from '../../../toolympus/hooks/useSchema';

interface IChecklistSelectorSchema {
  checklists: ChecklistConfig[];
  schema: Schema;
}

export const ChecklistSelectorContext = React.createContext<IChecklistSelectorSchema>({
  checklists: [],
  schema: { checklist_id: {} },
});

export const useChecklistSelectorSchema = () => useContext(ChecklistSelectorContext);

export const useChecklistSelectorSchemaData = (checklists: ChecklistConfig[]) => {
  const value: IChecklistSelectorSchema = useMemo(() => {
    return {
      checklists: checklists,
      schema: {
        checklist_id: createSelectSchema(checklists.map(cl => ({ value: cl._id, label: cl.title })), {
            label: "Чеклист",
        }),
      },
    }
  }, [checklists]);

  return value;
}

export const ChecklistSelectorSchemaProvider = (props: PropsWithChildren<Pick<IChecklistSelectorSchema, "checklists">>) => {
  const value = useChecklistSelectorSchemaData(props.checklists);

  return <ChecklistSelectorContext.Provider value={value}>
    {props.children}
  </ChecklistSelectorContext.Provider>
}
