import React from 'react';
import { Buttons, Dialog, DialogState, FormGrid, LoadingIndicator, OccupyFreeSpace } from '../../../toolympus/components/primitives';
import { ChecklistItem, ChecklistItemSchema, ChecklistsConfigData } from './useChecklistConfigs';
import { Button, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ThemeSettings } from '../../../theme';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { FormattedMessage } from 'react-intl';
import { ItemEditorProps, ListEditor } from '../../../toolympus/components/primitives/ListEditor';
import { generateCode } from '../../../toolympus/api/data';

interface Props extends Pick<DialogState, "isOpen" | "close"> {
  data: ChecklistsConfigData;
}

const ChecklistItemEditor = (props: ItemEditorProps<ChecklistItem>) => {
  return (
    <FormGrid noMargin columns="1fr" gap="dense">
      <FormControlsForFields
        fields={[
          ["title"],
        ]}
        data={props.item}
        onChange={(o,c) => props.update(c)}
        schema={ChecklistItemSchema}
        />
      
      <FormGrid noMargin columns="1fr 1fr" forceEvenColumns gap="dense">
        <FormControlsForFields
          fields={[
            ["roles_required"],
          ]}
          data={props.item}
          onChange={(o,c) => props.update(c)}
          schema={ChecklistItemSchema}
          />
      </FormGrid>
    </FormGrid>
  )
}

export const ChecklistsConfigurationDialog = (props: Props) => {
  const { data, ...otherProps } = props;
  return (
    <Dialog
      noFullscreen
      dialogTitle=""
      maxWidth="md"
      titleActions={<>
        {data.isLoading && <LoadingIndicator sizeVariant="s" />}
      </>}
      {...otherProps}>

      <FormGrid columns="200px 1fr" forceEvenColumns noMargin>
        <List style={{ background: "#F0F0F0", borderRight: `2px solid ${ThemeSettings.colors.primary}`}}>
          {data.data.map(cl => (
            <ListItemButton key={cl._id} onClick={() => data.editItem.startEditing({ ...cl })}>
              <ListItemText primary={cl.title} />
            </ListItemButton>
          ))}

          <ListItemButton onClick={() => data.createItem.run()}>
            <ListItemIcon><Add /></ListItemIcon>
            <ListItemText secondary="создать чеклист" />
          </ListItemButton>
        </List>

        {!!data.editItem.item &&
          <FormGrid columns="1fr" noMargin>
            <Buttons>
              <OccupyFreeSpace />
              {data.editItem.hasChanges && 
                <Button size="small" color="primary" onClick={() => data.editItem.save()}><FormattedMessage id="common.save" /></Button>}
            </Buttons>
            <FormControlsForFields
              fields={[
                ["title"]
              ]}
              schema={data.configSchema}
              data={data.editItem.item}
              onChange={(o,c) => data.editItem.update(c)}
              />

            <ListEditor
              createDflt={() => ({ _id: generateCode(), title: "" })}
              idField="_id"
              items={data.editItem.item.config?.items || []}
              update={v => data.editItem.update({ config: { ...(data.editItem.item?.config || {}), items: v}})}
              itemEditor={ChecklistItemEditor}
              extraActionsAsMenu
              />


            {data.editItem.item?.config?.items?.length > 3 && <Buttons>
              <OccupyFreeSpace />
              {data.editItem.hasChanges && 
                <Button size="small" color="primary" onClick={() => data.editItem.save()}><FormattedMessage id="common.save" /></Button>}
            </Buttons>}
          </FormGrid>}

        {!data.editItem.item &&
          <Typography>Выберите чеклист из списка слева</Typography>}

      </FormGrid>
      
    </Dialog>
  );
}
