import { apiFetch } from "../../../toolympus/api/core";
import { useAction } from "../../../toolympus/api/useAction";
import { useEditItem2, withActionOnSaveItem } from "../../../toolympus/api/useNewItem";
import { FileInfo } from "../../../toolympus/components/files";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { Schema } from "../../../toolympus/hooks/useSchema";

export interface AttachedFile extends Pick<FileInfo, "_id" | "filename" | "contenttype"> {

}

export interface ChecklistItem {
  _id: string;
  group_id?: string;
  title: string;
  entry_order?: number;
  is_checked?: boolean;
  comment?: string;
  files_attached?: AttachedFile[];
}

export interface ChecklistConfig {
  _id: string;
  title: string;
  config: {
    items: ChecklistItem[];
  },
}

export const ChecklistConfigSchema: Schema = {
  title: { label: "Название" },
}

export const ChecklistItemSchema: Schema = {
  title: { label: "Название" },
  roles_required: { label: "Может обновлять (роли)" },
}

export const useChecklistConfigs = (apiPath: string) => {
  const data = useLoadedData<ChecklistConfig[]>(apiPath, []);

  const editItem = useEditItem2<ChecklistConfig>({
    dontResetOnSave: true,
    setToSaved: true,
    getApiPath: r => `${apiPath}/${r._id}`,
  });

  const createItem = useAction(() => {
    return apiFetch<ChecklistConfig>(apiPath, "post", { title: "Новый чеклист", config: { items: [] }})
      .then(x => {
        data.reload();
        editItem.startEditing(x);
        return x;
      });
  });

  return {
    ...data,
    isLoading: data.isLoading || createItem.isRunning || editItem.isLoading,
    configSchema: ChecklistConfigSchema,
    itemSchema: ChecklistItemSchema,

    editItem: withActionOnSaveItem(editItem, x => { data.reload(); }),
    createItem,
  }
}


export type ChecklistsConfigData = ReturnType<typeof useChecklistConfigs>;
