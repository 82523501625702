import React, { useContext } from 'react';

interface IApplicationsInfoContext {
  labels: Record<number, string>;
}

export const ApplicationsInfoContext = React.createContext<IApplicationsInfoContext>({
  labels: {},
});

export const useApplicationsInfoContext = () => useContext(ApplicationsInfoContext);

