import React, { PropsWithChildren, useContext, useMemo } from "react";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { ChecklistItem } from "./useChecklistConfigs"
import { apiFetch } from "../../../toolympus/api/core";


export const useChecklistBatchData = (apiPath: string, batchId: string) => {
  const data = useLoadedData<ChecklistItem[]>(`${apiPath}?ext_ref=${batchId}`, [], !!apiPath && !!batchId);

  const update = (id: string, changes: Partial<ChecklistItem>) => {
    return apiFetch<ChecklistItem>(`${apiPath}/${id}`, "put", changes)
      .then(x => {
        data.setData(items => items.map(i => i._id === x._id ? x : i));
        return x;
      })
  }

  return {
    ...data,
    update,
  }
}

export type ChecklistsBatchData = ReturnType<typeof useChecklistBatchData>;

export type IChecklistBatchContext = Omit<ChecklistsBatchData, "setData" | "reload" | "loadBumper">;

export const ChecklistBatchContext = React.createContext<IChecklistBatchContext>({
  data: [],
  isLoading: false,
  update: () => Promise.resolve({} as ChecklistItem),
});


export const useChecklistBatch = () => useContext(ChecklistBatchContext);

export const ChecklistBatchProvider = (props: PropsWithChildren<{ data: ChecklistsBatchData}>) => {
  const value = useMemo(() => {
    return props.data;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.data]);

  return (
    <ChecklistBatchContext.Provider value={value}>
      {props.children}
    </ChecklistBatchContext.Provider>
  )
}
